import { useSelector } from "react-redux";
import FriendsInGridView from "./FriendsInGridView";
import NoRecordView from "./NoRecordView";
import { DELETE } from "../../Constants";
import { useDispatch } from "react-redux";
// import styles from "../../pages/searchGroom/SearchedPeopleToMarryGridView.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { connectedFriendIsDeleted } from "../../store/reducers/friendsSlice";
import { useState } from "react";
import {
  fetchFriendsOfActiveTab,
  performActionOnRequestAndUpdateRedux,
} from "../../pages/friends/FriendsLoader";
import { Loader } from "../UI/ErrorModal";
import { textFor } from "../../util/languages";
function ConnectedFriends() {
  const data = useSelector((state) => state.friends.connectedFriends);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");
  let hideDialog = null;
  //-------------------------------------------------------------------
  async function deleteFriend(personToViewDetail) {
    if (window.confirm(textFor("areYouSure"))) {
      setIsLoading("1");
      try {
        await performActionOnRequestAndUpdateRedux(
          personToViewDetail,
          DELETE,
          connectedFriendIsDeleted,
          dispatch
        );
      } finally {
        setIsLoading("");
        hideDialog("");
      }
    }
  }
  function getViewForRemoveButton(personToViewDetail) {
    return (
      <div className={commonStyles.nearImageBtnWrapper}>
        <button
          className={commonStyles.brownButton}
          onClick={() => deleteFriend(personToViewDetail)}
          type="button"
        >
          DELETE
        </button>
      </div>
    );
  }

  async function loadMoreClicked(e) {
    setIsLoading("1");
    await fetchFriendsOfActiveTab(
      dispatch,
      data.reqId,
      parseInt(data.pageNumber) + 1
    );
    setIsLoading("");
  }
  function showLoaderIfNeeds() {
    if (isLoading !== "") {
      console.log("Loader is active...");
      return <Loader />;
    } else {
      console.log("Loader is off.");
      return "";
    }
  }
  function setMethodToHidePersonDetailDialog(methodToHideDialog) {
    hideDialog = methodToHideDialog;
  }
  return data.elements.length > 0 ? (
    <>
      {showLoaderIfNeeds()}
      <FriendsInGridView
        data={data}
        loadMoreClicked={loadMoreClicked}
        getViewForTopRightArea={getViewForRemoveButton}
        setMethodToHidePersonDetailDialog={setMethodToHidePersonDetailDialog} //We are asking to give us access to the method to hide dialog
      />
    </>
  ) : (
    <NoRecordView />
  );
}

export default ConnectedFriends;
