import { textFor } from "../../util/languages";

function NoRecordView({ message = "useAddButtonForFriends" }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        // backgroundColor: "green",
        height: "40%",
        justifyContent: "center",
      }}
    >
      <div>
        <label>{textFor("noRecords")}</label>
        <i style={{ color: "black" }} className="far fa-face-frown"></i>
      </div>
      <label>{textFor(message)}</label>
    </div>
  );
}

export default NoRecordView;
