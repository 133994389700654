import { useState, useEffect } from "react";
import Dropdown from "../../../UI/dropdown/Dropdown";
import statesAndCities from "../../../../jsonConstants/statesAndCities.json";
import styles from "./AddressFields.module.scss";
const AddressFields = (props) => {
  // console.log("AddressField props=", props);
  const [cities, setCities] = useState([]);
  const { states } = statesAndCities;

  useEffect(() => {
    // Set cities based on the selected state
    if (props.usersStateId) {
      const state = states.find((state) => state.id === props.usersStateId);
      setCities(state ? state.cities : []);
      // console.log("new cities: ", state ? state.cities : []);
      // console.log("selectedCity = ", props.usersCityId);
    } else {
      console.log("In AddressFields, props.usersStateId is not having value.");
    }
  }, [props, states]);
  const handleStateChange = (event) => {
    // console.log("state changed: ", event.target.value);
    const selectedStateTargetValue = event.target.value;
    const selectedStateObj = states.find(
      (state) => state.id === selectedStateTargetValue
    );
    props.updateAddressStateId(selectedStateTargetValue);
    console.log(
      "Selected State ID:",
      selectedStateTargetValue,
      ",cities = ",
      selectedStateObj.cities
    );
    props.updateAddressCityId(""); // Reset selected city when state changes
    setCities(selectedStateObj ? selectedStateObj.cities : []);
  };

  const getAddressStateField = () => {
    return (
      <Dropdown
        id="addressStateDropdown"
        label="State/UT"
        value={props.usersStateId || ""}
        onChange={handleStateChange}
        options={states}
        disabled={!props.isEditing} //if not editing, disable it
      />
    );
  };
  const getAddressCityField = () => {
    return (
      <Dropdown
        id="addressCityDropdown"
        label="Nearest City"
        value={props.usersCityId || ""}
        onChange={(e) => props.updateAddressCityId(e.target.value)}
        options={cities}
        disabled={!props.usersStateId || !props.isEditing} //if not editing, disable it
      />
    );
  };
  const getDetailedAddressField = () => {
    return (
      <label className={styles.label}>
        Detailed Address:
        <textarea
          id="detailedAddress"
          value={props.usersDetailedAddress}
          rows={4}
          placeholder="Max 150 letters"
          maxLength={150}
          onChange={(e) => props.updateDetailedAddress(e.target.value)}
          className={styles.inputField}
          disabled={!props.isEditing}
        />
      </label>
    );
  };
  return (
    <>
      {getAddressStateField()}
      {getAddressCityField()}
      {getDetailedAddressField()}
    </>
  );
};

export default AddressFields;
