import { Link } from "react-router-dom";
import styles from "./welcome.module.scss";
import languageIconImageUrl from "../../src/assets/languageIcon.jpeg";
import AlertDialog from "../components/UI/alertDialog/AlertDialog";
import { useEffect, useState } from "react";
import { textFor } from "../util/languages";
import { LANGUAGE_KEY } from "../Constants";
import { clearLocalStorage } from "../util/auth";
import { useDispatch } from "react-redux";
import { clearGroomLists } from "../store/reducers/savedGroomListsSlice";
import { clearProfile } from "../store/reducers/userProfileSlice";
import { clearFamilyMembers } from "../store/reducers/familyMembersSlice";
import { clearFriends } from "../store/reducers/friendsSlice";

function Welcome() {
  console.log("Rendering Welcome page...");
  const [selectedLanguageOption, setSelectedLanguageOption] =
    useState("ENGLISH");
  const dispatch = useDispatch();
  const [showLanguageOptions, setSshowLanguageOptions] = useState("");
  useEffect(() => {
    clearLocalStorage();
    dispatch(clearGroomLists());
    dispatch(clearProfile());
    dispatch(clearFamilyMembers());
    dispatch(clearFriends());
    const currentLanguage = localStorage.getItem(LANGUAGE_KEY);
    if (currentLanguage) {
      switch (currentLanguage) {
        case "HINDI":
          setSelectedLanguageOption("HINDI");
          break;
        case "ENGLISH":
        default:
          setSelectedLanguageOption("ENGLISH");
      }
    }
  }, []);
  const handleChange = (event) => {
    setSelectedLanguageOption(event.target.value);
  };
  function getLanguageChooserView() {
    return (
      <>
        <label className={styles.container}>
          English
          <input
            type="radio"
            checked={selectedLanguageOption === "ENGLISH"}
            name="radio"
            value="ENGLISH"
            onChange={handleChange}
          />
          <span className={styles.checkmark}></span>
        </label>
        <label className={styles.container}>
          हिन्दी
          <input
            type="radio"
            checked={selectedLanguageOption === "HINDI"}
            name="radio"
            value="HINDI"
            onChange={handleChange}
          />
          <span className={styles.checkmark}></span>
        </label>
      </>
    );
  }
  function saveLanguage(e) {
    console.log(e.target.value);
    localStorage.setItem(LANGUAGE_KEY, selectedLanguageOption);
    hideLanguageOptionDialog(e);
  }
  function hideLanguageOptionDialog(e) {
    setSshowLanguageOptions("");
  }
  return (
    <div className={styles.welcomeBg}>
      {showLanguageOptions && (
        <AlertDialog
          onOkClick={saveLanguage}
          onBackdropClicked={hideLanguageOptionDialog}
          title={"choose language"}
          getContent={getLanguageChooserView}
        />
      )}
      <img
        src={languageIconImageUrl}
        alt="Language"
        className={styles.languageIcon}
        onClick={() => {
          setSshowLanguageOptions("1");
        }}
      ></img>

      <>
        {textFor("welcomeTitleLine1")}, <br />
        {textFor("welcomeTitleLine2")}... <br />
      </>
      <br />
      <Link className={`${styles.links} ${styles.loginButton}`} to="/login">
        {textFor("loginButton")}
      </Link>
      <br />
      <Link
        className={`${styles.links} ${styles.registerButton}`}
        to="/register"
      >
        {textFor("registerButton")}
      </Link>
    </div>
  );
}

export default Welcome;
