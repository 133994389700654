import { BASE_URL, CREATING_NEW_MEMBER } from "../../Constants";
import { doLogout, getAuthToken } from "../../util/auth";
import userPlaceholderImageUrl from "../../assets/userPlaceholder.png";
import { textFor } from "../../util/languages";
import { authActions } from "../../store/reducers/authSlice";
import { updateProfileDetails } from "../../store/reducers/userProfileSlice";
const submitFormDetails = async (
  props,
  formData,
  setIsEditing,
  setIsSubmitting,
  dispatch,
  activeRoute
) => {
  console.log("Submitting form data:", formData);
  const requestData = new FormData();
  requestData.append("id", props.registrationDetails.id); //mandatory, don't change
  requestData.append("ownerUserId", props.registrationDetails.ownerUserId); //mandatory, don't change

  async function fillAndGetChangedFields() {
    let changedFieldsCount = 0;

    if (
      formData.fullName !== "" &&
      formData.fullName !== props.registrationDetails.fullName
    ) {
      console.log("changed: fullName, ", formData.fullName);
      changedFieldsCount++;
      requestData.append("fullName", formData.fullName);
    }

    if (
      formData.birthYear !== "" &&
      formData.birthYear !== props.registrationDetails.birthYear
    ) {
      console.log(
        "changed: birthYear: ",
        formData.birthYear,
        "=",
        props.registrationDetails.birthYear
      );
      changedFieldsCount++;
      requestData.append("birthYear", formData.birthYear);
    }

    if (
      formData.genderType !== "" &&
      formData.genderType !== props.registrationDetails.genderType
    ) {
      console.log("changed: genderType");
      changedFieldsCount++;
      requestData.append("genderType", formData.genderType);
    }

    if (
      formData.gotraType !== "" &&
      formData.gotraType !== props.registrationDetails.gotraType
    ) {
      console.log(
        "changed: gotraType",
        formData.gotraType,
        "=",
        props.registrationDetails.gotraType
      );
      changedFieldsCount++;
      requestData.append("gotraType", formData.gotraType);
    }

    if (props.registrationDetails.familyRelationType !== "SELF") {
      if (
        formData.familyRelationType !==
        props.registrationDetails.familyRelationType
      ) {
        console.log("changed: familyRelationType");
        changedFieldsCount++;
        requestData.append("familyRelationType", formData.familyRelationType);
      }
    } else {
      //saving changes for self profile
      requestData.append(
        "familyRelationType",
        props.registrationDetails.familyRelationType
      ); //mandatory and don't change
    }

    if (
      formData.isPureVegetarian !== "" &&
      formData.isPureVegetarian !== props.registrationDetails.isPureVegetarian
    ) {
      console.log("changed: isPureVegetarian");
      changedFieldsCount++;
      requestData.append(
        "isPureVegetarian",
        formData.isPureVegetarian === "YES"
      );
    }
    if (
      formData.maritalStatusType !== "" &&
      formData.maritalStatusType !== props.registrationDetails.maritalStatusType
    ) {
      console.log("changed: maritalStatusType");
      changedFieldsCount++;
      requestData.append("maritalStatusType", formData.maritalStatusType);
    }
    if (
      formData.educationLevelType !== "" &&
      formData.educationLevelType !==
        props.registrationDetails.educationLevelType
    ) {
      console.log("changed: educationLevelType");
      changedFieldsCount++;
      requestData.append("educationLevelType", formData.educationLevelType);
    }
    if (
      formData.showEduDetails &&
      formData.educationDetails !== "" &&
      formData.educationDetails !== props.registrationDetails.educationDetails
    ) {
      console.log("changed: educationDetails");
      changedFieldsCount++;
      requestData.append("educationDetails", formData.educationDetails);
    }
    if (
      formData.weightRangeType !== "" &&
      formData.weightRangeType !== props.registrationDetails.weightRangeType
    ) {
      console.log("changed: weightRangeType");
      changedFieldsCount++;
      requestData.append("weightRangeType", formData.weightRangeType);
    }
    if (
      formData.heightFeetType !== "" &&
      formData.heightFeetType !== props.registrationDetails.heightFeetType
    ) {
      console.log("changed: heightFeetType");
      changedFieldsCount++;
      requestData.append("heightFeetType", formData.heightFeetType);
    }
    if (
      formData.heightInchesType !== "" &&
      formData.heightInchesType !== props.registrationDetails.heightInchesType
    ) {
      console.log("changed: heightInchesType");
      changedFieldsCount++;
      requestData.append("heightInchesType", formData.heightInchesType);
    }
    if (
      formData.isSpeciallyAbled !== "" &&
      formData.isSpeciallyAbled !== props.registrationDetails.isSpeciallyAbled
    ) {
      console.log("changed: isSpeciallyAbled");
      changedFieldsCount++;
      requestData.append(
        "isSpeciallyAbled",
        formData.isSpeciallyAbled === "YES"
      );
    }
    if (
      formData.showChallengeDetails &&
      formData.specialAbilityDetails !== "" &&
      formData.specialAbilityDetails !==
        props.registrationDetails.specialAbilityDetails
    ) {
      console.log("changed: specialAbilityDetails");
      changedFieldsCount++;
      requestData.append(
        "specialAbilityDetails",
        formData.specialAbilityDetails
      );
    }
    if (
      formData.yearlyIncome !== "" &&
      formData.yearlyIncome !== props.registrationDetails.yearlyIncome
    ) {
      console.log("changed: yearlyIncome");
      changedFieldsCount++;
      requestData.append("yearlyIncome", formData.yearlyIncome);
    }
    if (
      formData.incomeSourceDetails !== "" &&
      formData.incomeSourceDetails !==
        props.registrationDetails.incomeSourceDetails
    ) {
      console.log("changed: incomeSourceDetails");
      changedFieldsCount++;
      requestData.append("incomeSourceDetails", formData.incomeSourceDetails);
    }
    if (
      formData.otherDetails !== "" &&
      formData.otherDetails !== props.registrationDetails.otherDetails
    ) {
      console.log("changed: otherDetails");
      changedFieldsCount++;
      requestData.append("otherDetails", formData.otherDetails);
    }

    let fileBlob = "";
    //if props imag url is blank, then we keep placeholder, so if prop Img Url is empty and current profileImage is not
    //placeholder, that means user changed it.
    if (
      (!props.registrationDetails.profileImageUrl &&
        formData.profileImage !== userPlaceholderImageUrl) ||
      (props.registrationDetails.profileImageUrl &&
        formData.profileImage !== props.registrationDetails.profileImageUrl)
    ) {
      console.log(
        "changed:formData.profileImage ",
        formData.profileImage.length,
        "=",
        formData.profileImage
      );
      changedFieldsCount++;
      if (formData.isDefaultImageUsed) {
        requestData.append("profileImageUrl", "NULL");
      } else {
        fileBlob = await fetch(formData.profileImage).then((response) =>
          response.blob()
        );
        requestData.append("profileImage", fileBlob);
      }
    }
    return changedFieldsCount;
  }

  async function submitChangedFields() {
    try {
      let url = BASE_URL + "/normalUser/familyMember/selfDetails";
      let reqMethod = "PATCH";
      if (props.registrationDetails.familyRelationType !== "SELF") {
        url = BASE_URL + "/normalUser/familyMember";
        if (!props.registrationDetails.id) {
          reqMethod = "POST"; //it is the case of ADDING New member, SO id is blank
        }
      }
      const headers = {
        Authorization: `Bearer ${getAuthToken()}`,
        // "Content-Type": "multipart/form-data", this must be skipped, browser will send correct one.
      };
      console.log("authToken is = ", getAuthToken(), ", requestData is =");
      const objOfChangedItems = {};
      for (let pair of requestData.entries()) {
        console.log(pair[0] + ": " + pair[1]);
        objOfChangedItems[pair[0]] = pair[1];
      }

      const response = await fetch(url, {
        headers,
        method: reqMethod,
        body: requestData,
      });
      if (response.ok) {
        window.alert(textFor("detailsSavedSuccessfully"));
        //If this edit member form is used in crateMemberFlow, mark that as Saved
        //so that we will go back to allMembersView upon completion
        if (localStorage.getItem(CREATING_NEW_MEMBER) === "STARTED") {
          localStorage.setItem(CREATING_NEW_MEMBER, "SAVED");
        }
        if (activeRoute.pathname.includes("profile")) {
          console.log("UPDATING PROFILE CHANGES");
          objOfChangedItems.isProfileDirty = "true";
          dispatch(updateProfileDetails(objOfChangedItems));
        }
        setIsEditing(false);
        setIsSubmitting("");
      } else {
        if (response.status === 403) {
          doLogout(dispatch, authActions);
        }
        window.alert(textFor("cantSaveLoginAgain"));
      }
    } catch (error) {
      window.alert("Error:", error);
    } finally {
      setIsSubmitting("");
    }
  }

  let changedFieldsCount = await fillAndGetChangedFields();

  if (changedFieldsCount > 0) {
    setIsSubmitting("1");
    console.log("changedFieldsCount=", changedFieldsCount);
    await submitChangedFields();
  } else {
    window.alert(textFor("noFieldIsUpdated"));
  }
};

export default submitFormDetails;
