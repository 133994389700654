import ImageButton from "../../components/UI/imageButton/ImageButton";
import familyIconImageUrl from "../../assets/familyIcon.png";
import friendsIconImageUrl from "../../assets/friendsIcon.png";
import searchBrideIconImageUrl from "../../assets/searchBrideIcon.png";
import searchGroomIconImageUrl from "../../assets/searchGroomIcon.png";
import helpIconImageUrl from "../../assets/helpIcon.png";
import settingIconImageUrl from "../../assets/settingsIcon.png";
import styles from "./Dashboard.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LANGUAGE_KEY, SEARCH_GENDER } from "../../Constants";
import { Loader } from "../../components/UI/ErrorModal";
import { doNavigate } from "../../util/auth";
function Dashboard() {
  const navigate = useNavigate();
  //to make sure we show image first and then the button it
  const [familyIconLoaded, setFamilyIconLoaded] = useState("");
  const [friendIconLoaded, setFriendIconLoaded] = useState("");
  const [groomIconLoaded, setGroomIconLoaded] = useState("");
  const [brideIconLoaded, setBrideIconLoaded] = useState("");
  const [settingIconLoaded, setSettingIconLoaded] = useState("");
  const [helpIconLoaded, setHelpIconLoaded] = useState("");
  const [message, setMessage] = useState("");
  function atLeaseOneIconLoading() {
    return !(
      familyIconLoaded &&
      friendIconLoaded &&
      groomIconLoaded &&
      brideIconLoaded &&
      settingIconLoaded &&
      helpIconLoaded
    );
  }
  function decodeToken() {
    const token = localStorage.getItem("token");
    const arrayToken = token.split(".");
    // console.log("arrayToken=", JSON.parse(atob(arrayToken[1])));
    const tokenPayload = JSON.parse(atob(arrayToken[1]));
    console.log("tokenPayload = ", tokenPayload);
    localStorage.setItem("userId", tokenPayload.userId);
    localStorage.setItem("selfMemberId", tokenPayload.selfMemberId);
    localStorage.setItem(LANGUAGE_KEY, tokenPayload.userLanguage);
  }
  decodeToken();
  const familyIconClicked = (event) => {
    doNavigate(navigate, "/familyMembers");
  };
  const friendsIconClicked = (event) => {
    doNavigate(navigate, "/friends");
  };
  const searchGroomIconClicked = (event) => {
    localStorage.setItem(SEARCH_GENDER, "MALE");
    doNavigate(navigate, "/searchGroom");
  };
  const searchBrideIconClicked = (event) => {
    localStorage.setItem(SEARCH_GENDER, "FEMALE");
    doNavigate(navigate, "/searchGroom");
  };
  const settingIconClicked = (event) => {
    doNavigate(navigate, "/settings");
  };
  const helpIconClicked = (event) => {
    doNavigate(navigate, "/help");
  };
  function getFamilyButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="familyMembers"
          imageUrl={familyIconImageUrl}
          onClick={familyIconClicked}
          onImageLoad={setFamilyIconLoaded}
          buttonName="family"
        />
        {familyIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.familyBtnText}>Family</div>
          </div>
        )}
      </div>
    );
  }
  function getHelpButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="help"
          imageUrl={helpIconImageUrl}
          onClick={helpIconClicked}
          onImageLoad={setHelpIconLoaded}
          buttonName="help"
        />
        {helpIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.helpBtnText}>Help</div>
          </div>
        )}
      </div>
    );
  }
  function getSettingsButton() {
    return (
      <div
        className={`${styles.familyBtnContainer} ${styles.familyBtnBkColor}`}
      >
        <ImageButton
          id="settings"
          imageUrl={settingIconImageUrl}
          onClick={settingIconClicked}
          onImageLoad={setSettingIconLoaded}
          buttonName="settings"
        />
        {settingIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.settingsBtnText}>Settings</div>
          </div>
        )}
      </div>
    );
  }
  function getSearchBrideButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="searchBride"
          imageUrl={searchBrideIconImageUrl}
          onClick={searchBrideIconClicked}
          onImageLoad={setBrideIconLoaded}
          buttonName="searchBride"
        />
        {brideIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.brideBtnText}>Search Bride</div>
          </div>
        )}
      </div>
    );
  }
  function getSearchGroomButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="searchGroom"
          imageUrl={searchGroomIconImageUrl}
          onClick={searchGroomIconClicked}
          onImageLoad={setGroomIconLoaded}
          buttonName="searchGroom"
        />
        {groomIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.groomBtnText}>Search Groom</div>
          </div>
        )}
      </div>
    );
  }
  function getFriendsButton() {
    return (
      <div className={styles.familyBtnContainer}>
        <ImageButton
          id="friends"
          imageUrl={friendsIconImageUrl}
          onClick={friendsIconClicked}
          onImageLoad={setFriendIconLoaded}
          buttonName="friends"
        />
        {friendIconLoaded && (
          <div className={styles.familyBtnTextContainer}>
            <div className={styles.friendsBtnText}>Friends</div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className={styles.dashboardTopLevelContainer}>
      {atLeaseOneIconLoading() && <Loader />}
      <div className={styles.dashboardContainer}>
        {getFamilyButton()}
        {getFriendsButton()}
        {getSearchGroomButton()}
        {getSearchBrideButton()}
        {getSettingsButton()}
        {getHelpButton()}
      </div>
    </div>
  );
}

export default Dashboard;

//todo: for friend also we need address so prompt user to provide that before friend req check
