import { Suspense, useEffect, useState } from "react";
import {
  useRouteLoaderData,
  json,
  defer,
  Await,
  useNavigate,
} from "react-router-dom";
import { doLogout, doNavigate, getAuthToken } from "../../util/auth";
import {
  BASE_URL,
  BLOCKED_FRIENDS,
  REJECTED_REQUESTS,
  CONNECTED_FRIENDS,
  PENDING_REQUESTS,
  WAITING_REQUESTS,
  CONNECTED,
  BLOCKED,
  I_REQUESTED,
  REJECTED,
  THEY_REQUESTED,
} from "../../Constants";
import {
  fetchBlockedPersonsSuccess,
  fetchConnectedFriendsSuccess,
  fetchPendingRequestsSuccess,
  fetchRejectedRequestsSuccess,
  fetchWaitingRequestsSuccess,
} from "../../store/reducers/friendsSlice";
import FriendAndRequests, {
  getActiveTab,
} from "../../components/friendsAndRequests/FriendsAndRequests";
import styles from "./Friends.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { useDispatch } from "react-redux";
import { Loader } from "../../components/UI/ErrorModal";
import { authActions } from "../../store/reducers/authSlice";
import { useSelector } from "react-redux";
function FriendsLoader(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [auth.isAuthenticated, navigate]);
  const { routeLoaderData } = useRouteLoaderData("friends");
  const [isLoading, setIsLoading] = useState("");

  function getRefreshButtonView() {
    return (
      <button
        className={commonStyles.greenButton}
        onClick={(e) => doRefresh(e, dispatch, setIsLoading)}
      >
        Refresh
      </button>
    );
  }
  function getAddButtonView() {
    return (
      <button
        className={commonStyles.darkRedButton}
        onClick={(e) => {
          doNavigate(navigate, "/searchPeopleCriteria", {
            state: { from: "/source" },
          });
        }}
      >
        Add
      </button>
    );
  }
  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.flexContainer}>
        <div className={commonStyles.topPanel}>
          <div
            id="friendsAndRequests"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Friends & Requests:
          </div>
          {getRefreshButtonView()}
          {getAddButtonView()}
        </div>
        <Suspense fallback={<Loader />}>
          <Await resolve={routeLoaderData}>
            {(routeLoaderData) => <FriendAndRequests data={routeLoaderData} />}
          </Await>
        </Suspense>
      </div>
    </>
  );
}

export default FriendsLoader;
export async function loadFriends(dispatch) {
  return defer({
    routeLoaderData: fetchFriendsOfActiveTab(dispatch),
  });
}

async function doRefresh(e, dispatch, setIsLoading) {
  setIsLoading("1");
  const activeTabInFr = getActiveTab();
  console.log("active tab is : ", activeTabInFr);
  const reduxFriends = JSON.parse(localStorage.getItem("reduxState_friends"));
  switch (activeTabInFr) {
    case CONNECTED_FRIENDS:
      await fetchFriendsAndUpdateRedux(
        reduxFriends.connectedFriends,
        dispatch,
        fetchConnectedFriendsSuccess,
        0,
        0,
        CONNECTED,
        0
      );
      break;
    case BLOCKED_FRIENDS:
      await fetchFriendsAndUpdateRedux(
        reduxFriends.blockedPersons,
        dispatch,
        fetchBlockedPersonsSuccess,
        0,
        0,
        BLOCKED,
        0
      );
      break;
    case PENDING_REQUESTS:
      await fetchFriendsAndUpdateRedux(
        reduxFriends.pendingRequests,
        dispatch,
        fetchPendingRequestsSuccess,
        0,
        0,
        THEY_REQUESTED,
        0
      );
      break;
    case WAITING_REQUESTS:
      await fetchFriendsAndUpdateRedux(
        reduxFriends.waitingRequests,
        dispatch,
        fetchWaitingRequestsSuccess,
        0,
        0,
        I_REQUESTED,
        0
      );
      break;
    case REJECTED_REQUESTS:
      await fetchFriendsAndUpdateRedux(
        reduxFriends.rejectedRequests,
        dispatch,
        fetchRejectedRequestsSuccess,
        0,
        0,
        REJECTED,
        0
      );
      break;
    default:
      window.alert("Unknown case in doRefresh");
      break;
  }
  setIsLoading("");
}
export async function fetchFriendsOfActiveTab(
  dispatch,
  reqId = 0,
  pageNumber = 0
) {
  //find which tab is active in friend section then for that tab, return data from redux or localStorage if available
  //else fetch from server
  const activeTabInFr = getActiveTab();
  console.log("active tab is : ", activeTabInFr);
  switch (activeTabInFr) {
    case CONNECTED_FRIENDS:
      await ifNeedsFetchConnectedFriends(dispatch, reqId, pageNumber);
      break;
    case BLOCKED_FRIENDS:
      await ifNeedsFetchBlockedPersons(dispatch, reqId, pageNumber);
      break;
    case PENDING_REQUESTS:
      await ifNeedsFetchPendingRequests(dispatch, reqId, pageNumber);
      break;
    case WAITING_REQUESTS:
      await ifNeedsFetchWaitingRequests(dispatch, reqId, pageNumber);
      break;
    case REJECTED_REQUESTS:
      await ifNeedsFetchRejectedRequests(dispatch, reqId, pageNumber);
      break;
    default:
      window.alert("Unknown case in fetchFriendsOfActiveTab");
      break;
  }

  return []; //we will always return blank because the target views have subscribed
  //to redux for the data they need
}
async function ifNeedsFetchConnectedFriends(dispatch, reqId, pageNumber) {
  //todo check if the name of person contains single or double quotes, then the parse should not give problem
  //or just disallow the quotes in input
  const reduxFriends = JSON.parse(localStorage.getItem("reduxState_friends"));
  const connectedFriends = reduxFriends.connectedFriends;
  if (connectedFriends.elements.length < 1 || reqId !== 0) {
    await fetchFriendsAndUpdateRedux(
      connectedFriends,
      dispatch,
      fetchConnectedFriendsSuccess,
      reqId,
      pageNumber,
      CONNECTED
    );
  }
}
async function ifNeedsFetchBlockedPersons(dispatch, reqId, pageNumber) {
  //todo check if the name of person contains single or double quotes, then the parse should not give problem
  //or just disallow the quotes in input
  const reduxFriends = JSON.parse(localStorage.getItem("reduxState_friends"));
  const blockedPersons = reduxFriends.blockedPersons;
  if (blockedPersons.elements.length < 1 || reqId !== 0) {
    await fetchFriendsAndUpdateRedux(
      blockedPersons,
      dispatch,
      fetchBlockedPersonsSuccess,
      reqId,
      pageNumber,
      BLOCKED
    );
  }
}
async function ifNeedsFetchPendingRequests(dispatch, reqId, pageNumber) {
  //todo check if the name of person contains single or double quotes, then the parse should not give problem
  //or just disallow the quotes in input
  const reduxFriends = JSON.parse(localStorage.getItem("reduxState_friends"));
  const pendingRequests = reduxFriends.pendingRequests;
  if (pendingRequests.elements.length < 1 || reqId !== 0) {
    await fetchFriendsAndUpdateRedux(
      pendingRequests,
      dispatch,
      fetchPendingRequestsSuccess,
      reqId,
      pageNumber,
      THEY_REQUESTED
    );
  }
}
async function ifNeedsFetchWaitingRequests(dispatch, reqId, pageNumber) {
  //todo check if the name of person contains single or double quotes, then the parse should not give problem
  //or just disallow the quotes in input
  const reduxFriends = JSON.parse(localStorage.getItem("reduxState_friends"));
  const waitingRequests = reduxFriends.waitingRequests;
  if (waitingRequests.elements.length < 1 || reqId !== 0) {
    await fetchFriendsAndUpdateRedux(
      waitingRequests,
      dispatch,
      fetchWaitingRequestsSuccess,
      reqId,
      pageNumber,
      I_REQUESTED
    );
  }
}
async function ifNeedsFetchRejectedRequests(dispatch, reqId, pageNumber) {
  //todo check if the name of person contains single or double quotes, then the parse should not give problem
  //or just disallow the quotes in input
  const reduxFriends = JSON.parse(localStorage.getItem("reduxState_friends"));
  const rejectedRequests = reduxFriends.rejectedRequests;
  if (rejectedRequests.elements.length < 1 || reqId !== 0) {
    await fetchFriendsAndUpdateRedux(
      rejectedRequests,
      dispatch,
      fetchRejectedRequestsSuccess,
      reqId,
      pageNumber,
      REJECTED
    );
  }
}
async function fetchFriendsAndUpdateRedux(
  obj,
  dispatch,
  action,
  reqId,
  pageNumber,
  type,
  wait = 5
) {
  const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
  let objLastFetched = 0;
  if (Object.keys(obj).includes("lastFetchedOn")) {
    objLastFetched = obj.lastFetchedOn;
  }
  const diff = currentTimestampInSeconds - objLastFetched;
  console.log("timediff ........ ", diff);
  if (diff < wait && reqId === 0) {
    //wait for wait seconds before calling again
    return [];
  }

  if (pageNumber > 0) {
    //for very first req, when there is no data at all, then obj.totalPages would not be available
    pageNumber = parseInt(pageNumber) % parseInt(obj.totalPages);
  }
  let url =
    BASE_URL +
    "/normalUser/friendRequests?reqId=" +
    reqId +
    "&pageSize=12&pageNumber=" +
    pageNumber +
    "&friendRequestType=" +
    type;

  const headers = {
    Authorization: `Bearer ${getAuthToken()}`,
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log(
      "response from fetch during fetchFriendsAndReqSummary = ",
      response
    );

    if (!response.ok) {
      if (response.status === 403) {
        return doLogout(dispatch, authActions);
      } else {
        throw json(
          { message: "Could not fetch details for selected event." },
          {
            status: 500,
          }
        );
      }
    } else {
      const resData = await response.json();
      console.log("got response ", resData);
      dispatch(action(resData));
      return resData;
    }
  } catch (error) {
    // Handle errors, including 403 Forbidden
    console.error("Error during friends fetch:", error);
    const status = error.status;
    console.error("status = ", status);
    return error;
  }
}
/* 
we have refresh button to load the latest items, otherwise we will keep loading the items as 
backend sends us and during the last page, we will reset the pageNumber to 0 so that we will
again fetch from the beginning, so that way we will get the latest records also.
*/
export async function performActionOnRequestAndUpdateRedux(
  personToViewDetail,
  reqAction,
  reduxAction,
  dispatch
) {
  let url =
    BASE_URL +
    "/normalUser/updateFriendRequest?action=" +
    reqAction +
    "&friendRequestId=" +
    personToViewDetail.friendRequestId;

  const headers = {
    Authorization: `Bearer ${getAuthToken()}`,
  };

  try {
    const response = await fetch(url, {
      method: "PATCH",
      headers: headers,
    });
    console.log(
      "response from fetch during fetchFriendsAndReqSummary = ",
      response
    );

    if (!response.ok) {
      if (response.status === 403) {
        return doLogout(dispatch, authActions);
      } else {
        throw json(
          { message: "Could not fetch details for selected event." },
          {
            status: 500,
          }
        );
      }
    } else {
      console.log("got response ", response);
      dispatch(reduxAction(personToViewDetail));
      return "";
    }
  } catch (error) {
    // Handle errors, including 403 Forbidden
    console.error("Error during friends fetch:", error);
    return error;
  }
}

//todo when we are at searched person result and load 2nd page and then if we refresh, it shows only 12
