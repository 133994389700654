import React from "react";
import { Suspense } from "react";
import { useRouteLoaderData, defer, Await } from "react-router-dom";
import { getAuthToken } from "../util/auth";
import { BASE_URL } from "../Constants";
import { fetchProfileSuccess } from "../store/reducers/userProfileSlice";
import ProfileDetails from "../components/profileDetails/ProfileDetails";
import commonStyles from "../commonStyles.module.scss";
import { Loader } from "../components/UI/ErrorModal";

const ProfileLoader = () => {
  const { routeLoaderData } = useRouteLoaderData("profile");

  return (
    <>
      <div className={commonStyles.topPanel}>User profile:</div>
      <Suspense fallback={<Loader />}>
        <Await resolve={routeLoaderData}>
          {(routeLoaderData) => (
            <ProfileDetails registrationDetails={routeLoaderData} />
          )}
        </Await>
      </Suspense>
    </>
  );
};

export default ProfileLoader;

export async function loadProfile(dispatch, userProfile) {
  return defer({
    routeLoaderData: loadRegistrationDetails(dispatch, userProfile),
  });
}

export async function fetchProfileData() {
  const token = getAuthToken();
  const url = BASE_URL + "/normalUser/registrationDetail";
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log("response of regDetails = ", response);

    if (!response.ok) {
      if (response.status === 403) {
        return response;
      } else {
        throw new Error("Could not fetch details for selected event.");
      }
    } else {
      const resp = await response.json();
      console.log("fetchProfileData resp = ", resp);
      return resp;
    }
  } catch (error) {
    console.error("Error during profile fetch:", error);
    throw error;
  }
}

export async function fetchBasicData() {
  const token = getAuthToken();
  const url = BASE_URL + "/normalUser/familyMember/selfDetails";
  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    });
    console.log("response of selfDetails = ", response);
    if (!response.ok) {
      if (response.status === 403) {
        return response;
      } else {
        throw new Error("Could not fetch details for selected event.");
      }
    } else {
      const resp = await response.json();
      console.log(" resp fetchBasicData= ", resp);
      return resp;
    }
  } catch (error) {
    console.error("Error during profile fetch:", error);
    throw error;
  }
}
function updatePureVegFlag(fullProfile) {
  switch (fullProfile.isPureVegetarian) {
    case true:
      fullProfile.isPureVegetarian = "YES";
      break;
    case false:
      fullProfile.isPureVegetarian = "NO";
      break;
  }
}
function updateSpecialAbilityFlag(fullProfile) {
  switch (fullProfile.isSpeciallyAbled) {
    case true:
      fullProfile.isSpeciallyAbled = "YES";
      break;
    case false:
      fullProfile.isSpeciallyAbled = "NO";
      break;
  }
}
export async function loadRegistrationDetails(dispatch, userProfile) {
  //userProfile is from redux, so don't refetch if id is present
  console.log("In loadRegistrationDetails, userProfile = ", userProfile);
  if (!userProfile.id || userProfile.isProfileDirty) {
    try {
      const [regData, basicData] = await Promise.all([
        fetchProfileData(),
        fetchBasicData(),
      ]);
      console.log("regData = ", regData);
      console.log("basicData = ", basicData);
      let fullProfile = { ...regData, ...basicData };
      fullProfile.familyRelationType = "SELF";
      updatePureVegFlag(fullProfile);
      updateSpecialAbilityFlag(fullProfile);
      fullProfile.ownerUserId = localStorage.getItem("userId"); //for self owner is same
      console.log("fullProfile = ", fullProfile);
      dispatch(fetchProfileSuccess(fullProfile));
      return fullProfile;
    } catch (error) {
      // Handle errors, including 403 Forbidden
      const status = error.status;
      console.error("status = ", status);
      error.status = 403;
      return error;
    }
  } else {
    return userProfile;
  }
}
