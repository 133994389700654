import { CHOOSE_CRITERIA_AGAIN_AND_RETRY, LANGUAGE_KEY } from "../Constants";
let map = new Map();
export function initLanguageMap() {
  map.set("areYouSure", {
    eng: "Are you sure ?",
    hindi: "पक्का आप ये एक्शन करना चाहते हैं ?",
  });
  map.set("Bad credentials", {
    eng: "Invalid phone/email/password. Please retry by correcting details.",
    hindi:
      "लॉगिन के लिए उपयोग किये गए फ़ोन/ईमेल/पासवर्ड गलत है, कृपया सही जानकारी डालकर फिर से प्रयास करें.",
  });
  map.set("cantSaveLoginAgain", {
    eng: "Your login has expired, so details can't be saved. Please login again and retry...",
    hindi:
      "आपका लॉगिन निरस्त हो चुका है, इसलिए जानकारी सेव नहीं हो पायी, कृपया फिर से लॉगिन करने के बाद ट्राय करें.",
  });
  map.set(CHOOSE_CRITERIA_AGAIN_AND_RETRY, {
    eng: "Please choose the criteria again and retry search.",
    hindi: "कृपया पीछे जाकर सर्च फ़िल्टर फिर से सेलेक्ट करें.",
  });
  map.set("comingSoonMoreSettings", {
    eng: "Coming soon, options to change colors and text size.",
    hindi:
      "बहुत जल्दी अक्षरों की साइज एवं कलर को चेंज करने की सुविधा यहाँ पर होगी.",
  });
  map.set("detailsSavedSuccessfully", {
    eng: "Details saved successfully",
    hindi: "आपकी जानकारी सेव हो गयी है.",
  });
  map.set("emailUpdatedSuccessfully", {
    eng: "Your email ID has updated successfully.",
    hindi: "आपका ईमेल आई.डी. अपडेट हो गया है.",
  });

  map.set("friendsAndRequests", {
    eng: "Friends & Requests:",
    hindi: "फ्रेंड्स & रिक्वेस्ट्स:",
  });
  map.set("loginButton", { eng: "Login", hindi: "लॉगिन करें" });

  map.set("memberDeletedSuccessfully", {
    eng: "Member deleted successfully.",
    hindi: "मेंबर की डिटेल्स डिलीट कर दी गयी है.",
  });
  map.set("nameIsMandatoryField", {
    eng: "Name is mandatory.",
    hindi: "नाम टाइप करना ज़रूरी है.",
  });

  map.set("noFieldIsUpdated", {
    eng: "No field is changed.",
    hindi: "कोई भी जानकारी अपडेट नहीं की गयी है.",
  });

  map.set("noRecords", {
    eng: "No records...",
    hindi: "कोई रिकॉर्ड नहीं मिला...",
  });

  map.set("noSavedList", {
    eng: "No saved list:",
    hindi: "कोई लिस्ट नहीं मिली:",
  });

  map.set("passwordUpdatedSucessfully", {
    eng: "Your password has updated successfully.",
    hindi: "आपका पासवर्ड अपडेट हो गया है.",
  });
  map.set("phoneUpdatedSuccessfully", {
    eng: "Your phone number has updated successfully.",
    hindi: "आपका फ़ोन नंबर अपडेट हो गया है.",
  });
  map.set("pleaseLoginAgainToMakeSettingsEffective", {
    eng: "Your settings are changed, please login again to start using new settings.",
    hindi:
      "आपकी सेटिंग्स सेव हो गयी है, कृपया नयी सेटिंग्स का उपयोग करने के लिए फिर से लॉगिन करें.",
  });

  map.set("plsUseDifferentSearchFilter", {
    eng: "Please use different search filter.",
    hindi: "कृपया किसी दूसरे सर्च फ़िल्टर का उपयोग करें.",
  });

  map.set("registerButton", { eng: "Register", hindi: "रजिस्टर करें" });

  map.set("searchCriteria", {
    eng: "Search criteria:",
    hindi: "सर्च फिल्टर्स:",
  });

  map.set("selectAtLeastOnePerson", {
    eng: "Please select, at least one person, by touching yellow cirle. The selected persons will be saved in the list.",
    hindi:
      "कृपया, पीले-गोल निशान पर टच करके, कुछ लोगों को सेलेक्ट करें, उन्हें ही लिस्ट में सेव किया जाएगा.",
  });

  map.set("suggestionToSearchAndShortlistGrooms", {
    eng: "No saved list found. You can do new search and save the shortlisted members in a list to view them later. Click on New Search button to do the search.",
    hindi:
      "आपके लिए कोई लिस्ट नहीं मिली है, आप वर/वधु सर्च करने के बाद नयी लिस्ट बनाकर, उस लिस्ट में मनपसंद लोगों को सेव कर के रख सकते हैं, ताकि आपको फिर से उन्हें सर्च नहीं करना पड़ेगा. कृपया सर्च करने के लिए नयी सर्च बटन का उपयोग करें.",
  });
  map.set("topUpWalletFromProfile", {
    eng: "Your wallet balance is low, please add balance from Profile section.",
    hindi: "आपका वॉलेट बैलेंस कम है, कृपया प्रोफाइल में जाकर रिचार्ज करें.",
  });

  map.set("unknown_error", {
    eng: "We are sorry for this error, please refresh and retry.",
    hindi: "रूकावट के लिए खेद है, कृपया पेज को रिफ्रेश कर पुनः प्रयास करें.",
  });

  map.set("useAddButtonForFriends", {
    eng: "Use Add button to send new friend requests.",
    hindi: "नयी फ्रेंड रिक्वेस्ट सेंड करने के लिए ऐड बटन का यूज़ करें.",
  });

  map.set("welcomeTitleLine1", {
    eng: "Welcome to this site",
    hindi: "यादव अहीर समाज की इस वेब साईट पर",
  });

  map.set("welcomeTitleLine2", {
    eng: "For Yadav Ahir Community",
    hindi: "आपका हार्दिक स्वागत है",
  });

  map.set("dummy", { eng: "dummy", hindi: "dummy" });
}

export function textFor(key) {
  let langObj = {
    eng: "UNKNOWN-KEY",
    hindi: "अज्ञात आई.डी.",
  };
  if (map.has(key)) {
    langObj = map.get(key);
  }
  const currentLanguage = localStorage.getItem(LANGUAGE_KEY);
  if (currentLanguage) {
    switch (currentLanguage) {
      case "HINDI":
        return langObj.hindi;
      case "ENGLISH":
        return langObj.eng;
      default:
        return langObj.eng;
    }
  }
  return langObj.eng;
}
