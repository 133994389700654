import { useNavigate, json } from "react-router-dom";
import AlertDialog from "../alertDialog/AlertDialog";
import { useDispatch } from "react-redux";
import { BASE_URL } from "../../../Constants";
import { getAuthToken } from "../../../util/auth";
import {
  deleteList,
  renameList,
} from "../../../store/reducers/savedGroomListsSlice";
import styles from "./EditListDialog.module.scss";
import commonStyles from "../../../commonStyles.module.scss";
import { useRef } from "react";
function EditListDialog({ onClose, parentListId, parentListName }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newListNameRef = useRef(null);
  async function deleteListClicked(e) {
    const confirm = window.confirm("Are you sure to delete entire list ?");
    if (confirm) {
      localStorage.setItem("lastPath", "listMembers");
      try {
        const token = getAuthToken();
        let url = BASE_URL + "/grooms/list?listId=" + parentListId;
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          method: "DELETE",
          headers: headers,
        });
        console.log("response from fetch during delete list = ", response);

        if (!response.ok) {
          if (response.status === 403) {
            return response;
          } else {
            throw json({
              message: "Could not delete list id " + parentListId,
              status: 500,
            });
          }
        } else {
          window.alert("List deleted.");
          dispatch(deleteList(parentListId));
        }
      } catch (error) {
        console.log("Error in deleting list: ", parentListName);
      }
    }
  }
  async function renameButtonClicked(e) {
    const newListName = newListNameRef.current.value;
    if (newListName.length < 2 || newListName == parentListName) {
      window.alert("Invalid list name");
      return;
    }
    const confirm = window.confirm("Are you sure to rename list ?");

    if (confirm) {
      try {
        const token = getAuthToken();
        let url =
          BASE_URL +
          "/grooms/editList?listId=" +
          parentListId +
          "&newListName=" +
          newListName;
        const headers = {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          method: "PATCH",
          headers: headers,
        });
        console.log("response from fetch during rename list = ", response);

        if (!response.ok) {
          if (response.status === 403) {
            return response;
          } else {
            throw json({
              message: "Could not rename list id " + parentListId,
              status: 500,
            });
          }
        } else {
          window.alert("List renamed successfully");
          parentListName = newListName;
          dispatch(renameList({ parentListId, newListName }));
        }
      } catch (error) {
        console.log("Error in deleting list: ", parentListName);
      }
    }
  }
  function getDeleteListButtonView() {
    return (
      <button
        style={{ margin: "15px 0" }}
        className={commonStyles.brownButton}
        type="button"
        onClick={deleteListClicked}
      >
        Delete
      </button>
    );
  }
  function getListNameInTextBoxView() {
    return (
      <div className={styles.createNewListPanel}>
        <input
          ref={newListNameRef}
          type="text"
          placeholder={parentListName}
        ></input>
        <button
          className={commonStyles.magentaButton}
          onClick={renameButtonClicked}
          type="button"
        >
          Rename
        </button>
      </div>
    );
  }
  function getEditListView() {
    return (
      <>
        {getListNameInTextBoxView()}
        {getDeleteListButtonView()}
      </>
    );
  }
  return (
    <AlertDialog
      onOkClick={onClose}
      onBackdropClicked={onClose}
      title={"Edit List"}
      getContent={getEditListView}
      okButtonText="Cancel"
    />
  );
}

export default EditListDialog;
