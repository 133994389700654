import { useDispatch, useSelector } from "react-redux";
import styles from "./ViewForAvailableLists.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import { useRef, useState } from "react";
import { BASE_URL, SEARCH_GENDER, getRandomColorFromId } from "../../Constants";
import { doLogout, getAuthToken } from "../../util/auth";
import {
  addNewList,
  addMemberIds,
} from "../../store/reducers/savedGroomListsSlice";
import AlertDialog from "../../components/UI/alertDialog/AlertDialog";
import { authActions } from "../../store/reducers/authSlice";
import { Loader } from "../../components/UI/ErrorModal";

function ViewForAvailableLists({ hideAvailableListDialog, personStatus }) {
  const newListNameRef = useRef(null);
  const dispatch = useDispatch();
  const gender = localStorage.getItem(SEARCH_GENDER);
  const allSavedGroomLists = useSelector(
    (state) => state.savedGroomLists.savedGroomLists
  );
  const savedGroomLists = allSavedGroomLists.filter((l) => l.gender === gender);

  const [selectedList, markSelectedList] = useState(new Set());
  const [isCreatingNewList, setCreatingNewList] = useState("");
  //   console.log("In ViewForAvailableLists savedGroomLists=", savedGroomLists);
  const selectedMemberIds = personStatus
    .filter((person) => !person.addedInList && person.isChosen)
    .map((person) => person.id);
  const [isLoading, setIsLoading] = useState("");

  function markListSelected(value) {
    markSelectedList((prevState) => {
      return new Set().add(value); //to allow only one selection like radio button
    });
  }
  function createNewListClicked(e) {
    setCreatingNewList("1");
  }

  /* should we allow same person to be saved in multiple lists ? suppose I
          selected 5, 2 were in L1, 2 were in L2 and 1 was not in any So I added all
              of them into L3
              One: show that you already have PersonName in L1/L2 etc one by one.
              Two: Max 20 items allowed in L1. Pease select max 2 items for L1. */
  async function createButtonClicked(e) {
    const listName = newListNameRef.current.value;
    if (listName !== null && listName !== "" && listName.length > 1) {
      try {
        setIsLoading("1");
        let url =
          BASE_URL +
          "/grooms/createList?gender=" +
          gender +
          "&listName=" +
          encodeURIComponent(listName);
        let reqMethod = "POST";
        const headers = {
          Authorization: `Bearer ${getAuthToken()}`,
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          headers,
          method: reqMethod,
        });
        const resp = await response.json();
        console.log("create new list, response = ", resp);
        if (response.ok) {
          dispatch(
            addNewList({
              id: resp,
              membersCount: 0,
              memberIds: [],
              gender: gender,
              name: listName,
              userId: parseInt(localStorage.getItem("userId")),
            })
          );
          setCreatingNewList("");
          window.alert("List created successfully");
        } else {
          if (response.status === 403) {
            doLogout(dispatch, authActions);
          } else {
            window.alert("Error saving details.");
          }
        }
      } catch (error) {
        window.alert("Error:", error);
        doLogout(dispatch, authActions);
      } finally {
        setIsLoading("");
      }
    } else {
      window.alert("Invalid list name to create.");
    }
  }
  function getCreateNewListView() {
    if (isCreatingNewList) {
      return (
        <div className={styles.createNewListPanel}>
          <input
            className={styles.newListNameInput}
            ref={newListNameRef}
            type="text"
            placeholder="New List Name"
          ></input>
          <button
            className={commonStyles.darkRedButton}
            onClick={createButtonClicked}
            type="button"
          >
            create
          </button>
          <div
            className={styles.closeButton}
            onClick={(e) => {
              setCreatingNewList("");
            }}
          >
            <i className="fa-solid fa-xmark"></i>
          </div>
        </div>
      );
    } else {
      return (
        <button
          className={commonStyles.blueButton}
          type="button"
          onClick={createNewListClicked}
        >
          + Create New List
        </button>
      );
    }
  }
  function getListsWithCheckBox() {
    return savedGroomLists.map((s) => (
      <div
        onClick={(e) => {
          if (!isCreatingNewList) {
            e.stopPropagation();
            markListSelected(s.id);
          }
        }}
        key={s.id}
        className={`${styles.listNameRow} ${
          isCreatingNewList ? styles.disabled : ""
        }`}
      >
        {selectedList.has(s.id) ? (
          <i className="fa-regular fa-circle-check fa-1x"></i>
        ) : (
          <i className="fa-regular fa-circle fa-1x"></i>
        )}
        <div
          style={{
            userSelect: "none",
            color: getRandomColorFromId(s.id),
          }}
        >
          {s.name}
        </div>
      </div>
    ));
  }
  async function okClicked(e) {
    if (selectedList.size !== 0) {
      try {
        const listId = selectedList.values().next().value;
        let url =
          BASE_URL +
          "/grooms/addListMembers?listId=" +
          listId +
          "&memberIdList=" +
          selectedMemberIds.join(",");
        let reqMethod = "PATCH";
        const headers = {
          Authorization: `Bearer ${getAuthToken()}`,
          "Content-Type": "application/json",
        };
        const response = await fetch(url, {
          headers,
          method: reqMethod,
        });
        const resp = await response.json();
        console.log("add member to list, response = ", resp);
        if (response.ok) {
          window.alert("Members added successfully");
          dispatch(
            addMemberIds({ listId: listId, memberIds: selectedMemberIds })
          );
          hideAvailableListDialog();
        } else {
          if (response.status === 403) {
            doLogout(dispatch, authActions);
          } else {
            window.alert("Error adding members.");
          }
        }
      } catch (error) {
        window.alert("Error:", error);
        doLogout(dispatch, authActions);
      }
    } else {
      window.alert("Please choose a list.");
    }
  }
  return (
    <>
      {isLoading && <Loader />}
      <AlertDialog
        onOkClick={okClicked}
        onBackdropClicked={hideAvailableListDialog}
        title="Add to List"
        getContent={() => {
          return (
            <div>
              {getListsWithCheckBox()}
              <br />
              {getCreateNewListView()}
              <br />
              <br />
              <br />
            </div>
          );
        }}
      />
    </>
  );
}
export default ViewForAvailableLists;
