import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import store from "../store/index";
import { authActions } from "../store/reducers/authSlice";
import { Loader } from "../components/UI/ErrorModal";
import { doNavigate } from "../util/auth";
import {
  Form,
  redirect,
  Link,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import { isAPhoneNumber, isAnEmail } from "../util/emailPhoneChecker";
import { BASE_URL, LOGIN_STATUS } from "../Constants";
import styles from "./LoginPage.module.scss";
import { textFor } from "../util/languages";
function LoginPage() {
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  useEffect(() => {
    //TODO THIS SHOULD NOT BE REUIRED AS WE ARE ALREADY USING ROUTER LOADER
    if (isAuthenticated) {
      // Navigate to "/dashboard" route
      // Example with useNavigate from React Router
      doNavigate(navigate, "/dashboard");
    }
  }, [isAuthenticated, navigate]);

  const [username, setUsername] = useState("a1@b.com");
  const [password, setPassword] = useState("welcome");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    localStorage.setItem("phoneOrEmail", e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <div className={styles.loginBg}>
      {isSubmitting && <Loader />}
      <h2 className={styles.pageTitle}>Login</h2>
      <br />
      <br />
      <Form method="post">
        <div>
          <label className={styles.fieldLabel} htmlFor="username">
            Username:
          </label>
          <br />
          <input
            className={styles.inputField}
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={handleUsernameChange}
          />
        </div>
        <br />
        <div>
          <label className={styles.fieldLabel} htmlFor="password">
            Password:
          </label>
          <br />
          <input
            className={styles.inputField}
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>
        <br />
        <div>
          <button
            className={styles.loginPageSubmitBtn}
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </button>
        </div>
      </Form>
      <p>
        <Link to="/forgotPassword">Forgot Password?</Link>
      </p>
      <p>
        Don't have an account? <Link to="/register">Register</Link>
      </p>
      <a href="tel:+918447899392">Call Me</a>
    </div>
  );
}
export default LoginPage;

export async function action({ request, params }) {
  console.log("login action is called.. request = ", request);
  const data = await request.formData();
  const username = data.get("username").trim();

  if (!(isAPhoneNumber(username) || isAnEmail(username))) {
    return null; //alert will be shown while checking in isAPhoneNumber() and isAnEmail()
  }
  let url = BASE_URL + "/token/login";
  let loginData = {
    username,
    password: data.get("password").trim(),
  };
  try {
    const response = await fetch(url, {
      method: request.method,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loginData),
    });

    const responseJson = await response.json();
    if (!response.ok) {
      window.alert(textFor(responseJson.errorMessage));
      return "";
    }
    console.log("login response : ", responseJson);
    localStorage.setItem("token", responseJson.accessToken);
    store.dispatch(authActions.loginSuccess(responseJson.accessToken));
    localStorage.setItem(LOGIN_STATUS, "1");
    return redirect("/dashboard");
  } catch (e) {
    console.log("some error occurred: ", e);
    window.alert(textFor("unknown_error"));
  }
}
