import styles from "./TestView.module.scss";
function TestView() {
  return (
    <div className={styles.d1}>
      top heading and buttons
      <div className={styles.d2}>
        <div>image and edit image butttons</div>
        <div className={styles.d21}>this is scrollable view1</div>
        <div className={styles.d21}>this is scrollable view2</div>
        <div className={styles.d21}>this is scrollable view3</div>
        <div className={styles.d21}>this is scrollable view4</div>
      </div>
      <div className={styles.d3}>bottom panel with buttons</div>
    </div>
  );
}

export default TestView;
