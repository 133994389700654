import { useSelector } from "react-redux";
import FriendsInGridView from "./FriendsInGridView";
import { DELETE } from "../../Constants";
import { useDispatch } from "react-redux";
import commonStyles from "../../commonStyles.module.scss";
import { waitingRequestIsDeleted } from "../../store/reducers/friendsSlice";
import {
  fetchFriendsOfActiveTab,
  performActionOnRequestAndUpdateRedux,
} from "../../pages/friends/FriendsLoader";
import { Loader } from "../UI/ErrorModal";
import { useState } from "react";
import { textFor } from "../../util/languages";
import NoRecordView from "./NoRecordView";
function WaitingRequests() {
  const data = useSelector((state) => state.friends.waitingRequests);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");
  let hideDialog = null;
  //-------------------------------------------------------------------
  async function deleteFriend(personToViewDetail) {
    if (window.confirm(textFor("areYouSure"))) {
      setIsLoading("1");
      try {
        await performActionOnRequestAndUpdateRedux(
          personToViewDetail,
          DELETE,
          waitingRequestIsDeleted,
          dispatch
        );
      } finally {
        setIsLoading("");
        hideDialog("");
      }
    }
  }
  function getViewForRemoveButton(personToViewDetail) {
    return (
      <div className={commonStyles.nearImageBtnWrapper}>
        <button
          className={commonStyles.brownButton}
          onClick={() => deleteFriend(personToViewDetail)}
          type="button"
        >
          DELETE
        </button>
      </div>
    );
  }

  async function loadMoreClicked(e) {
    setIsLoading("1");
    await fetchFriendsOfActiveTab(
      dispatch,
      data.reqId,
      parseInt(data.pageNumber) + 1
    );
    setIsLoading("");
  }
  function showLoaderIfNeeds() {
    if (isLoading !== "") {
      console.log("Loader is active...");
      return <Loader />;
    } else {
      console.log("Loader is off.");
      return "";
    }
  }
  function setMethodToHidePersonDetailDialog(methodToHideDialog) {
    hideDialog = methodToHideDialog;
  }
  return data.elements.length > 0 ? (
    <>
      {showLoaderIfNeeds()}
      <FriendsInGridView
        data={data}
        loadMoreClicked={loadMoreClicked}
        getViewForTopRightArea={getViewForRemoveButton}
        setMethodToHidePersonDetailDialog={setMethodToHidePersonDetailDialog}
      />
    </>
  ) : (
    <NoRecordView />
  );
}

export default WaitingRequests;

//todo if I logout and login using different email/phone, it shows me friends of old users.
