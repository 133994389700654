import { useSelector } from "react-redux";
import FriendsInGridView from "../FriendsInGridView";
import { BASE_URL } from "../../../Constants";
import { doLogout, doNavigate, getAuthToken } from "../../../util/auth";
import { json, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import commonStyles from "../../../commonStyles.module.scss";
import { sendingFriendRequestSuccess } from "../../../store/reducers/friendsSlice";
import { fetchSearchedPeople } from "./SearchedPeopleLoader";
import { Loader } from "../../UI/ErrorModal";
import { useEffect, useState } from "react";
import { authActions } from "../../../store/reducers/authSlice";
import NoRecordView from "../NoRecordView";
function SearchedPeopleGridView() {
  const data = useSelector((state) => state.friends.searchedPersons);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");

  useEffect(() => {
    if (!auth.isAuthenticated) {
      doNavigate(navigate, "/welcome");
    }
  }, [auth.isAuthenticated, navigate]);

  let hideDialog = null;
  //--------------------------------------------------------------------
  //--------------------------------------------------------------------
  async function sendFriendshipRequest(personToViewDetail) {
    console.log(
      "sendFriendshipRequest: personToViewDetail=",
      personToViewDetail
    );
    setIsLoading("1");
    await sendFriendshipRequestAndUpdateRedux(personToViewDetail);
    setIsLoading("");
  }
  function getViewForAddFriendButton(personToViewDetail) {
    return (
      <div className={commonStyles.nearImageBtnWrapper}>
        <button
          className={commonStyles.greenButton}
          onClick={() => sendFriendshipRequest(personToViewDetail)}
          type="button"
        >
          Add Friend
        </button>
      </div>
    );
  }
  async function sendFriendshipRequestAndUpdateRedux(personToViewDetail) {
    let url =
      BASE_URL +
      "/normalUser/sendFriendRequest?memberIdList=" +
      personToViewDetail.id; //for now we are sending 1 person request at a time

    const headers = {
      Authorization: `Bearer ${getAuthToken()}`,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
      });
      console.log(
        "response from fetch during sendFriendshipRequestAndUpdateRedux = ",
        response
      );

      if (!response.ok) {
        if (response.status === 403) {
          console.log("got 403....");
          doLogout(dispatch, authActions);
        } else {
          throw json(
            {
              message: "Could not sendFriendshipRequestAndUpdateRedux.",
            },
            {
              status: 500,
            }
          );
        }
      } else {
        console.log("got response ", response);
        const respData = await response.json();
        console.log("respData = ", respData);
        dispatch(sendingFriendRequestSuccess(respData));
      }
    } catch (error) {
      // Handle errors, including 403 Forbidden
      console.error(
        "Error during sendFriendshipRequestAndUpdateRedux fetch:",
        error
      );
      const status = error.status;
      console.error("status = ", status);
    } finally {
      hideDialog("");
    }
  }
  async function loadMoreClicked(e) {
    console.log("load more searhed persons");
    setIsLoading("1");
    await fetchSearchedPeople(
      dispatch,
      data.reqId,
      parseInt(data.pageNumber) + 1
    );
    setIsLoading("");
  }
  function setMethodToHidePersonDetailDialog(methodToHideDialog) {
    hideDialog = methodToHideDialog;
  }
  function showLoaderIfNeeds() {
    if (isLoading !== "") {
      console.log("Loader is active...");
      return <Loader />;
    } else {
      console.log("Loader is off.");
      return "";
    }
  }
  return data.elements.length > 0 ? (
    <>
      {showLoaderIfNeeds()}
      <FriendsInGridView
        data={data}
        loadMoreClicked={loadMoreClicked}
        getViewForTopRightArea={getViewForAddFriendButton}
        setMethodToHidePersonDetailDialog={setMethodToHidePersonDetailDialog}
      />
    </>
  ) : (
    <NoRecordView message="plsUseDifferentSearchFilter" />
  );
}

export default SearchedPeopleGridView;
