import { useSelector } from "react-redux";
import FriendsInGridView from "./FriendsInGridView";
import { useDispatch } from "react-redux";
import commonStyles from "../../commonStyles.module.scss";
import { blockedPersonIsUnblocked } from "../../store/reducers/friendsSlice";
import { useState } from "react";
import {
  fetchFriendsOfActiveTab,
  performActionOnRequestAndUpdateRedux,
} from "../../pages/friends/FriendsLoader";
import { Loader } from "../UI/ErrorModal";
import { UNBLOCK } from "../../Constants";
import NoRecordView from "./NoRecordView";
function BlockedPersons() {
  const data = useSelector((state) => state.friends.blockedPersons);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState("");
  let hideDialog = null;
  //-------------------------------------------------------------------
  async function unblockFriend(personToViewDetail) {
    setIsLoading("1");
    try {
      //backend will accept targetMemberId instead of the row Id,
      //We did this because when we block a person, then we add the entry in blocked_member table
      //but we don't return any value from that endpoint, so we can just save the friendReq that we are
      //blocking and then the targetMemberId we can use to unblock.
      personToViewDetail.friendRequestId = personToViewDetail.targetMemberId;
      await performActionOnRequestAndUpdateRedux(
        personToViewDetail,
        UNBLOCK,
        blockedPersonIsUnblocked,
        dispatch
      );
    } finally {
      setIsLoading("");
      hideDialog("");
    }
  }
  function getViewForRemoveButton(personToViewDetail) {
    return (
      <div className={commonStyles.nearImageBtnWrapper}>
        <button
          className={commonStyles.greenButton}
          onClick={() => unblockFriend(personToViewDetail)}
          type="button"
        >
          UNBLOCK
        </button>
      </div>
    );
  }

  async function loadMoreClicked(e) {
    setIsLoading("1");
    await fetchFriendsOfActiveTab(
      dispatch,
      data.reqId,
      parseInt(data.pageNumber) + 1
    );
    setIsLoading("");
  }
  function showLoaderIfNeeds() {
    if (isLoading !== "") {
      console.log("Loader is active...");
      return <Loader />;
    } else {
      console.log("Loader is off.");
      return "";
    }
  }
  function setMethodToHidePersonDetailDialog(methodToHideDialog) {
    hideDialog = methodToHideDialog;
  }
  return data.elements.length > 0 ? (
    <>
      {showLoaderIfNeeds()}
      <FriendsInGridView
        data={data}
        loadMoreClicked={loadMoreClicked}
        getViewForTopRightArea={getViewForRemoveButton}
        setMethodToHidePersonDetailDialog={setMethodToHidePersonDetailDialog}
      />
    </>
  ) : (
    <NoRecordView />
  );
}

export default BlockedPersons;
