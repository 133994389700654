import { useNavigate } from "react-router-dom";
import styles from "./AllMembersGridView.module.scss";
import commonStyles from "../../commonStyles.module.scss";
import userPlaceholderImageUrl from "../../assets/userPlaceholder.png";
import { useEffect, useState } from "react";
import { doNavigate, getAuthToken } from "../../util/auth";
import { BASE_URL } from "../../Constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchMembersSuccess } from "../../store/reducers/familyMembersSlice";
import AlertDialog from "../UI/alertDialog/AlertDialog";
import { Loader } from "../UI/ErrorModal";
import { getAge } from "../../util/viewUtils";
import { textFor } from "../../util/languages";

function AllMembersGridView(props) {
  const dispatch = useDispatch();
  const reduxFamilyMembers = useSelector((state) => state.familyMembers);
  const [shouldShowActionDialog, setActionDialogView] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [members, setMembers] = useState(props.data);
  const navigate = useNavigate();
  const onAddMemberClick = (e) => {
    doNavigate(navigate, "/familyMembers/addNewMember");
  };
  //---------------------------------------------------------------------
  useEffect(() => {
    localStorage.removeItem("memberIdToEdit");
    localStorage.removeItem("memberIdToDelete");
    if (props.data === "logout") {
      doNavigate(navigate, "/welcome");
    }
  }, []);
  useEffect(() => {
    setMembers([...reduxFamilyMembers.members]);
  }, [reduxFamilyMembers.members]);
  //---------------------------------------------------------------------
  console.log("In AllFamilyMembersGrid props: ", props);
  console.log("props.data = ", props.data);
  //---------------------------------------------------------------------
  function showActionDialog(m) {
    localStorage.setItem("memberIdToEdit", m.id);
    localStorage.setItem("memberIdToDelete", m.id);
    setActionDialogView("1");
  }
  function hideActionDialog(e) {
    localStorage.removeItem("memberIdToEdit");
    localStorage.removeItem("memberIdToDelete");
    setActionDialogView("");
  }

  const editMember = () => {
    //we can use parameterized route also, for now, let's use localStorage
    doNavigate(navigate, "/familyMembers/editMember");
  };
  const deleteMember = async (e) => {
    const id = localStorage.getItem("memberIdToDelete");
    //api supports deleting list of ids, but for now we will delete one by one only
    //so we will have to send list having only one id
    async function submitRequestToDeleteMember() {
      try {
        let url = BASE_URL + "/normalUser/familyMembers";
        let reqMethod = "DELETE";
        const headers = {
          Authorization: `Bearer ${getAuthToken()}`,
          "Content-Type": "Application/json",
        };
        const deleteIdList = [id];
        const response = await fetch(url, {
          headers,
          method: reqMethod,
          body: JSON.stringify(deleteIdList),
        });
        if (response.ok) {
          window.alert(textFor("memberDeletedSuccessfully"));
          updateFamilyMembersInRedux(id, dispatch, reduxFamilyMembers);
          hideActionDialog();
        } else {
          window.alert("Error deleting member.");
        }
      } catch (error) {
        window.alert("Error:", error);
      }
    }
    const userConfirmed = window.confirm(textFor("areYouSure"));
    if (userConfirmed) {
      setIsDeleting(true);
      await submitRequestToDeleteMember();
      setIsDeleting(false);
    }
  };
  function getFullNameField(m) {
    return (
      <div className={commonStyles.fullNameWrapper}>
        <div className={commonStyles.fullName}>{m.fullName}</div>
      </div>
    );
  }
  function getImageAndShortDetails(m, profileImageUrl) {
    return (
      <div
        id={"memberId_" + m.id}
        onClick={(e) => showActionDialog(m)}
        key={m.id}
        className={styles.grid__item}
      >
        <div className={styles.imgDiv}>
          {/* <img src="http://18.119.47.173:81/pics/f1.jpeg" alt="" /> */}
          <img src={profileImageUrl} alt="profilePic" />
        </div>
        {getFullNameField(m)}
        {getGenderAndAgeFields(m)}
      </div>
    );
  }
  function getFirstGenderChar(m) {
    switch (m.genderType) {
      case "MALE":
      case "FEMALE":
        return m.genderType;
      default:
        return "X";
    }
  }

  function getGenderAndAgeFields(m) {
    return (
      <div>
        <p>{getFirstGenderChar(m) + ", " + getAge(m)}</p>
      </div>
    );
  }
  function getMember(m) {
    let profileImageUrl = m.profileImageUrl;
    if (!profileImageUrl) {
      profileImageUrl = userPlaceholderImageUrl;
    }
    return getImageAndShortDetails(m, profileImageUrl);
  }
  function getAddMoreButtonSection() {
    let title = "";
    const memberCount = reduxFamilyMembers.members.length;
    if (memberCount > 1) {
      title = memberCount + " Family Members:";
    } else {
      title = memberCount + " Family Member:";
    }
    return (
      <div className={commonStyles.topPanel}>
        <div id="familyMemberPageTitle" className={styles.pageTitle}>
          {title}
        </div>
        <button
          id="addMember"
          className={commonStyles.darkRedButton}
          type="button"
          onClick={onAddMemberClick}
        >
          Add Member
        </button>
      </div>
    );
  }
  function getGridWithAllMembers() {
    return (
      <div className={styles.grid}>
        {members.map((item, index) => (
          <div key={item.id} className={styles.grid__item}>
            {getMember(item)}
          </div>
        ))}
      </div>
    );
  }
  function getViewToShowAllMembers() {
    if (props.data === "logout") {
      return <></>;
    } else {
      return (
        <>
          {getAddMoreButtonSection()}
          {getGridWithAllMembers()}
        </>
      );
    }
  }
  function getActionDialogView() {
    return (
      <>
        <button
          id="memberActionEditButton"
          className={styles.editBtn}
          onClick={editMember}
          type="button"
        >
          Edit
        </button>
        <button
          id="memberActionDeleteButton"
          className={styles.deleteBtn}
          onClick={deleteMember}
          type="button"
        >
          Delete
        </button>
        <br />
        <br />
      </>
    );
  }
  function ifNeedsShowActionDialog() {
    if (shouldShowActionDialog) {
      return (
        <AlertDialog
          onOkClick={hideActionDialog}
          onBackdropClicked={hideActionDialog}
          title="Choose Action"
          getContent={getActionDialogView}
          okButtonText="Cancel"
        />
      );
    } else {
      return "";
    }
  }
  function updateFamilyMembersInRedux(deletedId, dispatch, reduxFamilyMembers) {
    const remainingMembers = reduxFamilyMembers.members.filter(
      (m) => m.id != deletedId
    );
    dispatch(fetchMembersSuccess(remainingMembers));
  }

  return (
    <>
      {isDeleting && <Loader />}
      {ifNeedsShowActionDialog()}
      {getViewToShowAllMembers()}
    </>
  );
}

export default AllMembersGridView;
